import React from 'react';
import { Link } from "gatsby";
import BurgerIcon from "./../images/svg/burger.svg"
import CloseIcon from "./../images/svg/close.svg"
import Logo from './ui/logo';
import { useStaticQuery, graphql } from "gatsby";
const Header = ({ showChildren }) => {
  const data = useStaticQuery(graphql `
    query {
      siteBuildMetadata {
        buildTime(formatString: "ddd, DD MMM YYYY")
      }
    }
  `);
  return (
  <>
    <nav className="flex px-4 py-2 text-xs text-gray-800 bg-white xl:px-6 secondary-nav">
      <ul className="flex lang-menu list-reset">
        <li>
          <span>EN</span><span class="px-1">|</span></li>
        <li>
          <span>ES</span>
        </li>
      </ul>
      <div className="ml-auto last-updated"><em>Last update: {data.siteBuildMetadata.buildTime}</em></div>
    </nav>
    <nav id = "menu"
    className = "fixed top-0 left-0 z-10 flex-col flex-wrap w-full h-auto text-sm text-gray-100 bg-gray-900 navbar xl:flex-row xl:sticky" >
      <div className = "fixed top-0 left-0 flex-wrap justify-center hidden w-full h-full px-4 py-4 bg-gray-900 xl:justify-between xl:flex wrapper xl:static xl:px-6" >
        {showChildren && (
          <div className="collapse navbar-collapse">
            <ul className="flex flex-col navbar-nav xl:flex-row">
              <li className="pb-4 xl:pb-0">
                <Logo/>
              </li>
              <li className="mr-6 nav-item">
                <a className="nav-link hover:text-blue" onClick={menuClick} href="#about">
                  About
                </a>
              </li>
              <li className="mr-6 nav-item hover:text-blue">
                <a className="nav-link hover:text-blue" onClick={menuClick} href="#ranking">
                  The Ranking
                </a>
              </li>
              <li className="mr-0 nav-item">
                <a className="nav-link hover:text-blue" onClick={menuClick} href="#emerging">
                  Emerging candidates
                </a>
              </li>
            </ul>
          </div>
        )}
        {!showChildren && (
          <div className="collapse navbar-collapse">
            <ul className="flex flex-col navbar-nav xl:flex-row">
              <li className="nav-item">
                <a className="nav-link hover:text-blue" onClick={menuClick} href="/">
                  The Ranking
                </a>
              </li>
            </ul>
          </div>
        )}

        <div className="navbar-collapse collapse">
          <ul className="flex flex-col navbar-nav xl:flex-row">
            <li className="nav-item">
              <Link className="nav-link hover:text-blue" onClick={menuClick} to="/who-is-jeffrey-einstein">
                Who is Jeffrey Einstein
              </Link>
            </li>
            <li className="ml-6 nav-item">
              <Link className="nav-link hover:text-blue" onClick={menuClick} to="/what-is-jer-pecunia-non-olet">
                What is JER™: Pecunia non olet
              </Link>
            </li>
            <li className="ml-6 nav-item">
              <Link className="nav-link hover:text-blue" onClick={menuClick} to="/how-is-jer-assessed">
                How is the JER™ assessed
              </Link>
            </li>
          </ul>
        </div>
      </div>
        <div className="flex justify-between p-4 xl:hidden">
        <Logo/>
        <button
          className="relative flex cursor-pointer open navbar-toggler"
          type="button"
          aria-label="Toggle navigation"
          onClick={navIconClick}
        >
        <BurgerIcon />
        </button>
        <button
          className="relative flex ml-auto cursor-pointer close navbar-toggler"
          type="button"
          aria-label="Toggle navigation"
          onClick={navIconClick}
        >
        <CloseIcon />
        </button>
      </div>
    </nav>
  </>
  )
}

/* TODO: move menu toggle to own component */
function navIconClick(e) {
  document.body.classList.toggle('active-nav');
};

function menuClick(e) {
  if (document.body.classList.contains('active-nav')) {
    document.body.classList.toggle('active-nav');
  }
};
export default Header;
