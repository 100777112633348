import React from "react";

const Logo = ({ className, customClass, children }) => (
<a className={`${className + ' ' + customClass}`}  href="/">Epstein ranking</a>
);
Logo.defaultProps = {
  className: 'mr-6 font-extrabold tracking-wider nav-item navbar-brand',
};

export default Logo;
